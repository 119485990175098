import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import loadable from '@loadable/component'
import Layout from '../components/layout'
import SEO from '../components/seo'
import PlatformWidget from '../components/platformWidget'
import { addGoogleAnalyticsPageData } from '../utils/googleAnalyticsEvents'

const Footer = loadable(() => import('../components/footer/footer'))

const TeamsPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const seoTitle = 'فريق الشرق | الشرق للأخبار'
  const seoDescription =
    'تضم الشرق نخبة من أفضل الاعلاميين والمذيعين المرموقين في الوطن العربي ووجوهاً معروفة وذات مصداقية وخبرة واسعة في مجال الإعلام.'

  const canonicalUrl = `${process.env.GATSBY_BASE_URL}فريق-الشرق/`

  const iframeUrl =
    'https://www.asharqbusiness.com/iframe/media-center/teams/ar/presenters-and-anchors'

  useEffect(() => {
    addGoogleAnalyticsPageData()
  }, [])

  return (
    <>
      <Layout location={location} title={siteTitle} lang={'ar'}>
        <h1 className="customH1">{'فريق الشرق'}</h1>
        <SEO
          title={seoTitle}
          lang="ar"
          description={seoDescription}
          meta={[
            {
              property: `keywords`,
              content: `ترددات قناة الشرق , تردد قناة الشرق نايل سات , تردد قناة الشرق عرب سات , تردد قناة الشرق هوت بيرد`
            }
          ]}
          canonicalUrl={canonicalUrl}
        />
        <PlatformWidget url={iframeUrl} borderTop={false} bgColor={'#f8f8f8'} />
        <Footer />
      </Layout>
    </>
  )
}

export default TeamsPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
